import type { AppRouter } from '~/server/routers/_app'
import { httpBatchLink } from '@trpc/client'
import { createTRPCNext } from '@trpc/next'
import { serverConfig } from '~/config'

const getBaseUrl = () => {
	if (typeof window !== 'undefined') {
		// browser should use relative path
		return ''
	}

	if (serverConfig.vercel.url) {
		// reference for vercel.com
		return `https://${serverConfig.vercel.url}`
	}

	// assume localhost
	return `http://localhost:${serverConfig.port}`
}

export const trpc = createTRPCNext<AppRouter>({
	config: () => {
		return {
			links: [
				httpBatchLink({
					url: `${getBaseUrl()}/api/trpc`,
				}),
			],
		}
	},
	ssr: false,
})

import { useEffectOnce } from 'usehooks-ts'

const useHtmlComment = (text: string, node = null) => {
	useEffectOnce(() => {
		const n = node || document
		const comment = n.createComment(text)
		n.insertBefore(comment, n.children[0])

		return () => {
			n.removeChild(comment)
		}
	})
}

export default useHtmlComment

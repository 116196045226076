import { useEffectOnce } from 'usehooks-ts'
import useHtmlComment from '~/client/hooks/useHtmlComment'

const useSignedWork = () => {
	useHtmlComment('Made with 💙 by: The Web Team (https://theweb.team)')

	useEffectOnce(() => {
		console.log(`

Made with 💙 by: https://theweb.team

		`)
	})
}

export default useSignedWork

import type { AppProps } from 'next/app'
import type { NextPage, NextPageWithLayout } from 'next'
import type { ReactElement, ReactNode } from 'react'
import type { SiteSettingsSchema } from '~/server/services/SanityService/siteSettings'
import type { Organization } from 'schema-dts'
import PlausibleProvider from 'next-plausible'
import { Analytics } from '@vercel/analytics/react'
import Head from 'next/head'
import { jsonLdScriptProps } from 'react-schemaorg'
import { useRouter } from 'next/router'
import useSignedWork from '~/client/hooks/useSignedWork'
import { trpc } from '~/lib/trpc'
import { publicConfig } from '~/config'
import '../styles/globals.css'

declare module 'next' {
	type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<P, IP> & {
		getLayout?: (page: ReactElement, pageProps: IP) => ReactNode
	}
}

type AppPropsWithLayout = AppProps<{ siteSettings: SiteSettingsSchema }> & {
	Component: NextPageWithLayout
}

const App = (props: AppPropsWithLayout) => {
	const {
		pageProps,
		Component,
	} = props

	const router = useRouter()

	const getLayout = Component.getLayout ?? ((page) => {
		return page
	})

	useSignedWork()

	return (
		<PlausibleProvider
			domain={publicConfig.plausible.domain}
			exclude="/studio/**"
		>
			<Head>
				<title>
					Ecogreen
				</title>
				{!router.pathname.startsWith('/studio') ? (
					<script
						{...jsonLdScriptProps<Organization>({
							'@context': 'https://schema.org',
							'@type': 'Organization',
							url: publicConfig.vercel.url,
							logo: `${publicConfig.vercel.url}/images/ecogreen_logo.png`,
							contactPoint: [
								{
									'@type': 'ContactPoint',
									telephone: pageProps.siteSettings?.companyPhoneNumber,
									email: pageProps.siteSettings?.companyEmailAddress,
									contactType: 'Company Representative',
								},
							],
						})}
					/>
				) : null}
			</Head>
			{getLayout(<Component {...pageProps} />, pageProps)}
			<Analytics />
		</PlausibleProvider>
	)
}

export default trpc.withTRPC(App)

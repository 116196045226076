const vercelUrl = process.env.NEXT_PUBLIC_VERCEL_URL ?
		`https://${process.env.NEXT_PUBLIC_VERCEL_URL}` as string :
	'http://localhost:3000'

export const publicConfig = {
	sanity: {
		projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID as string,
		dataset: process.env.NEXT_PUBLIC_SANITY_DATASET as string,
		useCdn: process.env.NEXT_PUBLIC_SANITY_USE_CDN === 'true',
	},
	version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA as string,
	vercel: {
		url: vercelUrl,
	},
	plausible: {
		domain: process.env.NEXT_PUBLIC_PLAUSIBLE_DOMAIN as string,
	},
}

export const serverConfig = {
	port: Number(process.env.PORT as string),
	vercel: {
		url: vercelUrl,
	},
	sanity: {
		projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID as string,
		dataset: process.env.NEXT_PUBLIC_SANITY_DATASET as string,
		useCdn: process.env.NEXT_PUBLIC_SANITY_USE_CDN === 'true',
	},
	revalidateToken: process.env.REVALIDATE_TOKEN as string,
	postmark: {
		apiKey: process.env.POSTMARK_API_KEY as string,
	},
}
